import { PrismicNextImage } from "@prismicio/next";
import Styles from "./BrandBanner.module.css";

/**
 * @typedef {import("@prismicio/client").Content.BrandBannerSlice} BrandBannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<BrandBannerSlice>} BrandBannerProps
 * @param {BrandBannerProps}
 */
const BrandBanner = ({ slice: { items } }) => {
	return (
		<section className={Styles.wrapper}>
			<div className={Styles.container} data-contain="true">
				{items.map((item, index) => (
					<PrismicNextImage field={item?.image} key={index} />
				))}
			</div>
		</section>
	);
};

export default BrandBanner;
